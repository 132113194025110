<template>
    <div id="SalesPlanning">
        <v-container fluid app>
            <ZnapHotGrid
                :options="options"
            >
            </ZnapHotGrid>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapHotGrid from '@/components/znap/HotGridEmployee.vue'

export default {
    name:'SalesPlanning',

    components:{
        ZnapHotGrid
    },

    data: () => ({
        options: [
            {
                column: 'id_event',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Evento *',
                required: true,
                filterable: false,
                endpoint: [ Vue.prototype.$ipEvent, 'event' ]
            },
            {
                column: 'id_purpose',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Propósito *',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'purpose' ]
            },
            {
                column: 'id_cost_center_main',
                is: 'ComboBox',
                items: [],
                multiple: true,
                label: 'Centro de custo principal *',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'main-cost-center' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_purpose",
                        operator: "=",
                        value: null // pegar dinâmico
                    },
                ]
            },
            {
                column: 'id_cost_center',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Centro de custo *',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'purpose-cost-center' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_purpose",
                        operator: "IN",
                        value: null // pegar dinamico
                    },
                ]
            },
            {
                column: 'id_employee',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Colaborador *',
                required: true,
                filterable: true,
                endpoint: []
            },
            {
                column: 'id_account_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Conta gerencial',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'purpose-account' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_purpose",
                        operator: "=",
                        value: null // pegar dinâmico
                    }
                ]
            },
            {
                column: 'id_chart_account',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Conta contabil *',
                required: false,
                filterable: true,
                endpoint: [ Vue.prototype.$ipAccount, 'chart-account-group' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_account_group",
                        operator: "=",
                        value: null // pegar dinâmico
                    }
                ]
            },
            {
                column: 'id_sales_channel',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Tipo de contrato',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipSales, 'sales-channel', 'list-options-planning' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_sales_channel_type",
                        operator: "=",
                        value: null
                    },
                    {
                        AndOr: "AND",
                        column: "id_planning_group_version",
                        operator: "=",
                        value: null
                    },
                    {
                        AndOr: "AND",
                        column: "id_event_type",
                        operator: "=",
                        value: null
                    },
                    {
                        AndOr: "AND",
                        column: "fiscal_year",
                        operator: "=",
                        value: null
                    }
                ]
            },
            {
                column: 'id_sales_force',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Gerente executivo',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipSales, 'sales-force', 'list-options-planning' ],
                conditions: [
                    {
                        AndOr: "AND",   
                        column: "id_sales_force_type",
                        operator: "=",
                        value: null
                    },
                    {
                        AndOr: "AND",
                        column: "id_planning_group_version",
                        operator: "=",
                        value: null
                    },
                    {
                        AndOr: "AND",
                        column: "id_event_type",
                        operator: "=",
                        value: null
                    },
                    {
                        AndOr: "AND",
                        column: "fiscal_year",
                        operator: "=",
                        value: null
                    },
                    {
                        AndOr: "AND",
                        column: "id_sales_channel",
                        operator: "=",
                        value: null, // pegar dinâmico
                    },
                ]
            },
            {
                column: 'id_sales_force_bp',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'BP',
                required: false,
                filterable: true,
                endpoint: [ Vue.prototype.$ipSales, 'sales-force', 'list-options-planning' ],
                conditions: [
                    {
                        AndOr: "AND",   
                        column: "id_sales_force_type",
                        operator: "=",
                        value: 7
                    },
                    {
                        AndOr: "AND",
                        column: "id_planning_group_version",
                        operator: "=",
                        value: null
                    },
                    {
                        AndOr: "AND",
                        column: "id_event_type",
                        operator: "=",
                        value: null
                    },
                    {
                        AndOr: "AND",
                        column: "fiscal_year",
                        operator: "=",
                        value: null
                    }
                ]
            },
            {
                column: 'id_customer_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Conta cliente',
                required: false,
                filterable: true,
                endpoint: [ Vue.prototype.$ipCustomer, 'customer-group', 'list-options-planning' ],
                conditions: [
                    {
                        AndOr: "AND",   
                        column: "id_customer_group_type",
                        operator: "=",
                        value: null
                    },
                    {
                        AndOr: "AND",
                        column: "id_planning_group_version",
                        operator: "=",
                        value: null
                    },
                    {
                        AndOr: "AND",
                        column: "id_event_type",
                        operator: "=",
                        value: null
                    },
                    {
                        AndOr: "AND",
                        column: "fiscal_year",
                        operator: "=",
                        value: null
                    },
                    {
                        AndOr: "AND",
                        column: "id_sales_channel",
                        operator: "=",
                        value: null, // pegar dinâmico
                    },
                    {
                        AndOr: "AND",
                        column: "id_sales_force",
                        operator: "=",
                        value: null, // pegar dinâmico
                    },
                ]
            },
        ]
    }),
}
</script>

<style scoped>
</style>